/* GENERAL STYLES */
.software-page {
	font-size: 2em;
	font-family: 'Montserrat', sans-serif;
}

.norwester {
	font-family: 'Norwester', sans-serif;
}

.h-75vh {
	height: 75vh;
}

.h-100vh {
	height: 100vh;
}

.h-25vh {
	height: 25vh;
}

.mh-50vh {
	max-height: 50vh;
}

.mw-50 {
	max-width: 50%
}

.mw-75 {
	max-width: 75%;
}

.w-45 {
	width: 45%;
}

/* SOFTWARE NAV */

.software-nav {
	font-size: 1em;
}

#logo-link {
	max-height: 3em;
}

/* HERO SECTION */

.hero-section {
	height: calc(100vh - 3.7em);
	font-size: 0.8em;
}

.griffcodes-h1 {
	font-size: 2.3em;
}

.hi-im-kayla {
	font-size: 1em;
	font-weight: 600;
}

#hero-img {
	-moz-object-fit: cover;
	-webkit-object-fit: cover;
	-ms-object-fit: cover;
	object-fit: cover;
	-moz-object-position: center;
	-webkit-object-position: center;
	-ms-object-position: center;
	object-position: center;
	display: block;
	width: 100%;
	height: 100%;
	border-radius: 0;
}

/* HEADER BANNER COMPONENT */
.header-banner-text {
	font-size: 2em;
	text-shadow: 3px 3px #021121;
}

.single-work-div {
	flex-wrap: wrap;
}

.project-text {
	max-width: 50vw;
}

.work-img-container {
	max-width: 45vw;
	/* max-height: 50vh; */
	min-height: 100%;
	width: 100%;
	overflow: hidden;
	position: relative;
}

.software-work-img {
	-moz-object-fit: cover;
    -webkit-object-fit: cover;
    -ms-object-fit: cover;
    object-fit: cover;
    -moz-object-position: center;
    -webkit-object-position: center;
    -ms-object-position: center;
    object-position: center;
	position: relative;
	top: 0;
    border-radius: 0;
	width: 100%;
	height: 100%;
}

.project-title {
	font-size: 1em;
	font-weight: bold;
}

.project-description {
	font-size: 0.7em;
}

/* CONTACT SECTION */
.contact-header {
	font-weight: bold;
	font-size: 1.3em;
}

.contact-user-info {
	width: 100%;
}

.contact-label {
	font-size: 0.5em;
}

#contactMessage {
	height: 15em;
}

/* FOOTER SECTION */
.software-footer {
	color: grey;
	font-size: .5em;
}

.icons {
	list-style: none;
	display: flex;
	justify-content: space-between;
}

.footer-link {
	display: block;
	border: 1px solid grey;
	padding: .5em;
	border-radius: 50%;
}

.footer-link:hover {
	background: grey;
}

svg {
	font-size: 1.4em;
	display: block;
	fill: grey;
}

.footer-link:hover > svg {
	fill: white;
}

@media screen and (max-width: 991px) {
	.icons {
		font-size: 0.7em;
		padding-left: 0;
	}
	.work-img-container {
		max-width: 100%;
	}
	.project-text {
		max-width: 100vw;
	}

}

@media screen and (min-width: 992px) {
	.contact-user-info {
		width: 45%
	}
}