//custom variables
$primary: #0a3b70;
$gcgreen: #ead47a;
$primarydark: #021121;

// import the functions & variables
@import '../../node_modules/bootstrap/scss/_functions';
@import '../../node_modules/bootstrap/scss/_variables';

$custom-theme-colors: (
	"primarydark": $primarydark,
	"gcgreen": $gcgreen
);

$theme-colors: map-merge($custom-theme-colors, $theme-colors);

$theme-colors-rgb: map-loop($theme-colors, to-rgb, "$value");
$utilities-colors: map-merge(
  $theme-colors-rgb,
  (
    "black": to-rgb($black),
    "white": to-rgb($white),
    "body":  to-rgb($body-color)
  )
);

$utilities-text-colors: map-loop($utilities-colors, rgba-css-var, "$key", "text");
$utilities-bg-colors: map-loop($utilities-colors, rgba-css-var, "$key", "bg");

$custom-spacers: (
	6: $spacer * 4.5,
	7: $spacer * 6,
	8: $spacer * 9
);

$spacers: map-merge($spacers, $custom-spacers);

.hero-section {
	@media screen and (max-width: 767px) {
		font-size: 0.6em;
		height: calc(100vh - 1em);
		.griffcodes-h1 {
			margin-top: 1em !important;
		}
		#hero-img {
			max-height: 40%;
		}
	}
	@media only screen and (max-device-width: 1000px) and (orientation: landscape) {
		height: auto !important;
		#hero-img {
			display: none !important;
		}
	}
	// @media (min-width: 571px) and (max-width: 767px) {
	// 	margin-top: 7.5em;
	// }
}

.navbar-nav {
	@media screen and (max-width: 570px) {
		padding-left: 0.7em;
	}
}

.card-text {
	font-size: 0.65em;
}

//import bootstrap
@import '../../node_modules/bootstrap/scss/bootstrap.scss';

